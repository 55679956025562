/* eslint-disable no-undef */
import React from 'react';
import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@chakra-ui/react';

// import { eventLogger } from '../../api/eventLogger';
import { useApp, useAuth } from '../../hooks';
import { SUBSCRIPTION_PLANS, themeColorHex } from '../../constants';
import UpgradePlans from './Plan/UpgradePlans';
import { useUserLocation } from '../../queries';

const Upgrade = () => {
  const { user, userSubscriptionPlan } = useAuth();
  const { isPricingPlansVisible, showPlans, hidePlans } = useApp();
  useUserLocation();

  if (!user?.email || !userSubscriptionPlan || userSubscriptionPlan === SUBSCRIPTION_PLANS.PRO) {
    return null;
  }

  const handleUpgradeClick = () => {
    showPlans();
    // eventLogger(token, 'upgrade-clicked', null, user.email);
  };

  return (
    <Box>
      <Button
        gap="2"
        px={2}
        _hover={{ opacity: 0.8 }}
        rounded={0}
        zIndex={999}
        background={themeColorHex}
        fontWeight="400"
        border="1px solid"
        borderColor={themeColorHex}
        color="#FFFFFF"
        borderRadius={5}
        size={{ base: 'sm', md: 'md' }}
        onClick={handleUpgradeClick}
      >
        <Image src="/assets/images/go-pro.svg" alt="Go Pro" />
        <Text display={{ base: 'none', md: 'block' }}>Go Pro</Text>
      </Button>
      <Modal onClose={hidePlans} size="xl" isOpen={isPricingPlansVisible}>
        <ModalOverlay />
        <ModalContent minW={{ base: '95%', md: '75%' }} maxW={{ base: '95%', md: '75%' }}>
          <ModalCloseButton />
          <ModalBody mx={{ base: 0, md: 5 }} px={{ base: 0 }}>
            <UpgradePlans closePlans={hidePlans} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Upgrade;
