import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Text,
  VStack,
  Image,
  Center,
  Input,
  Spacer,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithCredential,
  updateProfile,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail
} from 'firebase/auth';

import { getHomePageUrl } from '../../utils/urlHelpers';
import { useAuth, useParams } from '../../hooks';
import { Button } from '../shared';
import { PRIVACY_URL, TERMS_OF_USE_URL, USER_ROLES } from '../../constants';
import { Link, useNavigate } from 'react-router-dom';
import { createStudent } from '../../api';
import { Capacitor } from '@capacitor/core';
import { removeUrlParam } from '../../utils';

const ClasswiseLogin = () => {
  const { auth, user, signOut } = useAuth();
  const navigate = useNavigate();
  const { redirectTo } = useParams();
  const [emailLogin, setEmailLogin] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [signup, setSignup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const isNativeApp = useMemo(() => Capacitor.isNativePlatform(), []);

  const mainLoginDisplay = !emailLogin && !forgotPassword && !signup;

  const loginCallback = useCallback(async (result) => {
    if (result?.user) {
      const { email, displayName, uid, accessToken } = result.user;
      await createStudent({
        token: accessToken,
        student: { email, name: displayName, uid, role: USER_ROLES.STUDENT }
      });
    }
  }, []);

  const checkParamsAndRedirect = React.useCallback(() => {
    const url = !!redirectTo ? redirectTo : getHomePageUrl();
    const cleanUrl = removeUrlParam(url, 'isLoggedIn');
    
    navigate(`${cleanUrl}?isLoggedIn=true`);
  }, [navigate, redirectTo]);

  const handleGoogleSignIn = async () => {
    const result = await signInWithPopup(auth, new GoogleAuthProvider());
    if (result?.user) {
      await loginCallback(result);
      checkParamsAndRedirect();
    }
  };

  const emailSignInButtonClickHandler = async () => {
    setErrorMessage('');
    if (document) {
      const email = document.getElementsByName('emailAuthEmailAddress')[0].value;
      const pass = document.getElementsByName('emailAuthPassword')[0].value;
      let result = null;
      try {
        result = await signInWithEmailAndPassword(auth, email, pass);

        if (result?.user) {
          await loginCallback(result);
          checkParamsAndRedirect();
        } else {
          setErrorMessage('Something went wrong! Try again.');
        }
      } catch (error) {
        if (error.code == 'auth/user-not-found') {
          setErrorMessage('User Not Found. Create a New Account');
        } else if (error.code == 'auth/missing-password') {
          setErrorMessage('Password cannot be empty');
        } else if (error.code == 'auth/wrong-password') {
          const providers = await fetchSignInMethodsForEmail(auth, email);
          if (providers.includes('password')) {
            setErrorMessage('Incorrect Password');
          } else if (providers.includes('google.com')) {
            setErrorMessage('Previously signed in with Google. Redirecting to Google Sign in...');
            setTimeout(() => {
              handleGoogleSignIn();
            }, 2000);
          } else {
            setErrorMessage('Something went wrong!');
          }
        } else {
          setErrorMessage('Something went wrong!');
        }
        console.log(error.code + ':' + error.message);
      }
    }
  };

  const emailSignupButtonClickHandler = async () => {
    setErrorMessage('');
    if (document) {
      const email = document.getElementsByName('emailSignupEmailAddress')[0].value;
      const pass = document.getElementsByName('emailSignupPassword')[0].value;
      const name = document.getElementsByName('emailSignupName')[0].value;
      let result = null;
      try {
        result = await createUserWithEmailAndPassword(auth, email, pass);

        if (result) {
          result.user['displayName'] = name;
          await updateProfile(result.user, { displayName: name });
          await loginCallback(result);
          checkParamsAndRedirect();
        } else {
          setErrorMessage('Something went wrong! Try again.');
        }
      } catch (error) {
        if (error.code == 'auth/email-already-in-use') {
          setErrorMessage('You already have an account with that email');
        } else if (error.code == 'auth/invalid-email') {
          setErrorMessage('Invalid Email');
        } else if (error.code == 'auth/weak-password') {
          setErrorMessage('Password is too weak');
        } else {
          setErrorMessage('Something went wrong! Try again later.');
        }
        console.log(error.code + ':' + error.message);
      }
    }
  };

  const emailResetPasswordButtonClickHandler = async () => {
    setErrorMessage('');
    if (document) {
      const email = document.getElementsByName('emailPasswordResetEmailAddress')[0].value;
      const actionCodeSettings = {
        url: import.meta.env.NEXT_PUBLIC_TEACHER_APP_URL
      };
      try {
        await sendPasswordResetEmail(auth, email, actionCodeSettings);
        setErrorMessage('Password Reset Email sent!');
      } catch (error) {
        if (error.code == 'auth/invalid-email') {
          setErrorMessage('Invalid Email');
        } else if (error.code == 'auth/user-not-found') {
          setErrorMessage('No account found. Signup to create an account');
        } else {
          setErrorMessage('Something went wrong!');
        }
        console.log('error:', error);
      }
    }
  };

  React.useEffect(() => {
    window.__classwiseGoogleAuthHandler__ = async (response) => {
      const idToken = response.credential;
      const credential = GoogleAuthProvider.credential(idToken);
      try {
        const result = await signInWithCredential(auth, credential);
        await loginCallback(result);
        checkParamsAndRedirect();
      } catch (error) {
        console.error('firebase login with credential error: ', error);
      }
    };
    setTimeout(() => {
      // Following to be used if the Google YOLO is to be opened as a floating object
      // window?.google?.accounts.id.initialize({
      //   client_id: import.meta.env.NEXT_PUBLIC_CLIENT_ID,
      //   callback: window.__classwiseGoogleAuthHandler__
      // });
      // eslint-disable-next-line no-unused-vars
      // window?.google?.accounts.id.prompt((notification) => {
      // console.log('notification:', notification);
      // });
    }, 1000);
  }, [auth, redirectTo, navigate, checkParamsAndRedirect, loginCallback]);

  const setEmailLoginDisplay = () => {
    setErrorMessage('');
    setEmailLogin(true);
    setForgotPassword(false);
    setSignup(false);
  };

  const setForgotPasswordDisplay = () => {
    setErrorMessage('');
    setEmailLogin(false);
    setForgotPassword(true);
    setSignup(false);
  };

  const setSignupDisplay = () => {
    setErrorMessage('');
    setEmailLogin(false);
    setForgotPassword(false);
    setSignup(true);
  };

  return (
    <Flex justify="center" align="center" p="8" w="100%" h="100vh" backgroundColor={'#F3F5F9'}>
      <Box p="8" h="100%">
        <VStack spacing={5}>
          <Image src="/assets/images/logo.png" alt="classwise-logo" />
          <Box py={3} textAlign={'center'}>
            <Text fontSize={'35px'} fontWeight="bold">
              Welcome to
            </Text>
            <Text fontSize={'30px'} fontWeight="bold" color={'rgb(62 135 193)'}>
              Classwise 👋
            </Text>
          </Box>

          {mainLoginDisplay && (
            <Center flexDir="column" gap="6">
              <Center flexDir="column" gap={5}>
                <Button
                  maxW="374px"
                  width="100%"
                  _hover={{
                    opacity: 0.8
                  }}
                  rounded={0}
                  background="#FFFFFF"
                  fontWeight="400"
                  border="1px solid"
                  borderColor="#FFFFFF"
                  fontFamily="Helvetica"
                  fontSize="14"
                  color="#757575"
                  height="40px"
                  shadow="md"
                  onClick={() => setEmailLoginDisplay()}
                >
                  <Image
                    src="/assets/images/email.png"
                    boxSize="20px"
                    width="24px"
                    marginRight="13px"
                  />
                  Sign in with Email
                </Button>
                {!isNativeApp && (
                  <Button
                    onClick={handleGoogleSignIn}
                    maxW="374px"
                    w="100%"
                    _hover={{
                      opacity: 0.8
                    }}
                    color="#757575"
                    height="40px"
                    paddingX="16px"
                    shadow="md"
                    background="#FFFFFF"
                    fontWeight="400"
                    border="1px solid"
                    borderColor="#FFFFFF"
                  >
                    <Image
                      src="/assets/images/google.png"
                      boxSize="20px"
                      marginRight="13px"
                      alt="Google"
                    />
                    Sign in with Google
                  </Button>
                )}
                <Box id="classwise-google-login">
                  <Box
                    id="g_id_onload"
                    position="relative"
                    data-client_id={import.meta.env.NEXT_PUBLIC_CLIENT_ID}
                    data-callback="__classwiseGoogleAuthHandler__"
                    data-prompt_parent_id="classwise-google-login"
                    data-cancel_on_tap_outside="true"
                  />
                </Box>

                <Flex justify="center">
                  <Text fontSize="sm" w={{ base: '280px', md: '680px' }} color="#686868">
                    By signing up for a Classwise account you are agreeing to our{' '}
                    <Link to={TERMS_OF_USE_URL} target="_blank" color="blue.400">
                      terms of service
                    </Link>{' '}
                    and{' '}
                    <Link to={PRIVACY_URL} target="_blank" color="blue.400">
                      data privacy policy
                    </Link>
                    .
                  </Text>
                </Flex>
                {user && <Button onClick={signOut}>Sign out</Button>}
              </Center>
            </Center>
          )}
          {emailLogin && (
            <Center flexDir="column" gap="2" maxWidth="400px">
              <Text
                alignSelf="flex-start"
                fontSize="12px"
                color="#4A90E2"
                cursor="pointer"
                onClick={() => setEmailLogin(false)}
              >
                Back
              </Text>
              <Center flexDir="column" width="100%">
                <Input
                  placeholder="Enter email address (e.g. abc@example.com)"
                  fontSize="14px"
                  variant="filled"
                  bgColor="white"
                  size="md"
                  rounded={0}
                  name="emailAuthEmailAddress"
                />
                <PasswordInput rounded={0} name="emailAuthPassword" />
              </Center>
              <Flex flexDir="row" width="100%" fontSize="14px">
                <Flex color="red">
                  <Text noOfLines="2" maxWidth="225px">
                    {errorMessage}
                  </Text>
                </Flex>
                <Spacer />
                <Text color="#4A90E2" cursor="pointer" onClick={() => setForgotPasswordDisplay()}>
                  Forgot Password?
                </Text>
              </Flex>
              <Button
                maxWidth="227px"
                width="100%"
                _hover={{
                  opacity: 0.8
                }}
                rounded={0}
                background="#5D38DB"
                fontWeight="400"
                border="1px solid"
                borderColor="#5D38DB"
                fontFamily="Helvetica"
                fontSize="14"
                color="#FFFFFF"
                height="40px"
                shadow="md"
                mt="4"
                loadingText="Signing in..."
                isLoading={false} // This was always false lol
                onClick={(e) => emailSignInButtonClickHandler(e)}
              >
                Sign in
              </Button>
              <Text
                fontSize="14px"
                minW={{ base: '80%', lg: '350px' }}
                textAlign="center"
                textDecoration="underline"
              >
                New User?{' '}
                <Link color="#4A90E2" onClick={() => setSignupDisplay()}>
                  Click here to Create a free account
                </Link>
              </Text>
            </Center>
          )}
          {forgotPassword && (
            <Center flexDir="column" gap="2" maxWidth="400px">
              <Text color="#686868" fontWeight="700" fontSize="19px">
                Reset Password
              </Text>
              <Text alignSelf="flex-start" fontSize="13px" color="#686868">
                Enter your registered email address below to reset password. We will send a reset
                password link to your registered email address
              </Text>
              <Input
                placeholder="Enter email address (e.g. abc@example.com)"
                fontSize="14px"
                variant="filled"
                bgColor="white"
                size="md"
                rounded={0}
                name="emailPasswordResetEmailAddress"
                mt="4"
              />
              <Text color="red" fontSize="14px" alignSelf="flex-start">
                {errorMessage}
              </Text>
              <Button
                maxWidth="227px"
                width="100%"
                _hover={{
                  opacity: 0.8
                }}
                rounded={0}
                background="#5D38DB"
                fontWeight="400"
                border="1px solid"
                borderColor="#5D38DB"
                fontFamily="Helvetica"
                fontSize="14"
                color="#FFFFFF"
                height="40px"
                shadow="md"
                mt="4"
                loadingText="Signing in..."
                onClick={(e) => emailResetPasswordButtonClickHandler(e)}
              >
                Reset Password
              </Button>
              <Text fontSize="14px">
                Remember Password?{' '}
                <Link color="#4A90E2" onClick={() => setEmailLoginDisplay()}>
                  Click here to login
                </Link>
              </Text>
            </Center>
          )}
          {signup && (
            <Center flexDir="column" gap="2" maxWidth="400px">
              <Text color="#686868" fontWeight="700" fontSize="19px">
                Create New Account
              </Text>
              <Center flexDir="column" width="100%">
                <Input
                  placeholder="Enter your name"
                  fontSize="14px"
                  variant="filled"
                  bgColor="white"
                  size="md"
                  rounded={0}
                  name="emailSignupName"
                />
                <Input
                  placeholder="Enter email address (e.g. abc@example.com)"
                  fontSize="14px"
                  variant="filled"
                  bgColor="white"
                  size="md"
                  rounded={0}
                  name="emailSignupEmailAddress"
                />
                <PasswordInput rounded={0} name="emailSignupPassword" />
              </Center>
              <Flex color="red" fontSize="14px" alignSelf="flex-start">
                <Text>{errorMessage}</Text>
              </Flex>
              <Button
                maxWidth="227px"
                width="100%"
                _hover={{
                  opacity: 0.8
                }}
                rounded={0}
                background="#5D38DB"
                fontWeight="400"
                border="1px solid"
                borderColor="#5D38DB"
                fontFamily="Helvetica"
                fontSize="14"
                color="#FFFFFF"
                height="40px"
                shadow="md"
                mt="4"
                loadingText="Signing in..."
                onClick={(e) => emailSignupButtonClickHandler(e)}
              >
                Create Account
              </Button>
              <Text
                fontSize="14px"
                minW={{ base: '80%', lg: '350px' }}
                textAlign="center"
                textDecoration="underline"
              >
                <Link color="#4A90E2" onClick={() => setEmailLoginDisplay()}>
                  Already have an account? Click here to Login.
                </Link>
              </Text>
            </Center>
          )}
        </VStack>
      </Box>
    </Flex>
  );
};

function PasswordInput(props) {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup size="md">
      <Input
        type={show ? 'text' : 'password'}
        placeholder="Enter password"
        bgColor="white"
        fontSize="14px"
        {...props}
      />
      <InputRightElement width="80px">
        <Button h="30px" size="sm" onClick={handleClick}>
          {show ? 'Hide' : 'Show'}
        </Button>
      </InputRightElement>
    </InputGroup>
  );
}

export default ClasswiseLogin;
