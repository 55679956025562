import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { SUBSCRIPTION_PLANS, themeColorHex } from '../../../constants';
import { useConsumption } from '../../../queries';
import { useApp, useAuth } from '../../../hooks';

const UpgradeBanner = () => {
  const { data, isLoading } = useConsumption();
  const { userSubscriptionPlan } = useAuth();
  const { showPlans } = useApp();
  if (isLoading || userSubscriptionPlan === SUBSCRIPTION_PLANS.PRO || data?.credits > 1) {
    return null;
  }

  let bannerMessage = "You're about to run out of daily free credits (1 left).";
  if (data?.credits === 0) {
    bannerMessage = 'Your daily free credit limit has been exhausted.';
  }

  return (
    <Flex
      h="50px"
      w="100vw"
      backgroundColor={themeColorHex}
      color="#FFFFFF"
      left="0"
      alignItems="center"
      justifyContent="center"
    >
      {bannerMessage}
      <Text ml={2} color="yellow" as="u" cursor="pointer" onClick={showPlans}>
        Upgrade Now
      </Text>
    </Flex>
  );
};

export default UpgradeBanner;
