import React, { useCallback } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useIdToken, useSignOut } from 'react-firebase-hooks/auth';

import { useFirebase } from './useFirebase';
import { SUBSCRIPTION_PLANS } from '../constants';

const AuthContext = createContext({ user: null });

const BASE_EMPTY = {};

const AuthProvider = ({ children }) => {
  const { auth, googleAuthProvider } = useFirebase();
  const [userSubscriptionPlan, setUserSubscriptionPlan] = React.useState(SUBSCRIPTION_PLANS.FREE);
  const [signOut, isLogoutLoading] = useSignOut(auth || BASE_EMPTY);

  const updateSubscription = useCallback(
    (subscriptionPlan) => {
      if (!!subscriptionPlan) {
        setUserSubscriptionPlan((currentPlan) => {
          if (currentPlan !== subscriptionPlan) {
            return subscriptionPlan;
          }
          return currentPlan;
        });
      }
    },
    [setUserSubscriptionPlan]
  );

  const [user, isLoginLoading, error] = useIdToken(auth || BASE_EMPTY, {
    onUserChanged: (user) => {
      if (user?.reloadUserInfo?.customAttributes !== undefined) {
        const customClaims = JSON.parse(user.reloadUserInfo.customAttributes);
        updateSubscription(customClaims?.studentSubscription);
      }
    }
  });

  useEffect(() => {
    return auth?.onIdTokenChanged(async (user) => {
      if (!user) {
        console.info(`no token found`);
        return;
      }
      console.info(`token changed: `, user.accessToken);
      const decodedToken = await user.getIdTokenResult();
      updateSubscription(decodedToken?.claims?.studentSubscription);
    });
  }, [auth, user, updateSubscription]);

  // force refresh the token every 10 minutes
  useEffect(() => {
    const handle = setInterval(async () => {
      console.info(`refreshing token`);
      const user = auth?.currentUser;
      if (user) await user.getIdToken(true);
    }, 10 * 60 * 1000);
    return () => clearInterval(handle);
  }, [auth]);

  const value = useMemo(
    () => ({
      user,
      auth,
      signOut,
      token: user?.accessToken,
      error,
      userSubscriptionPlan,
      googleAuthProvider,
      loading: isLoginLoading || isLogoutLoading
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      auth,
      error,
      googleAuthProvider,
      isLoginLoading,
      isLogoutLoading,
      user?.accessToken,
      signOut,
      user,
      userSubscriptionPlan
    ]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth can only be used inside AuthContext');
  }
  return context;
};

export { useAuth, AuthProvider };
