import React from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  Spinner,
  Text,
  useDisclosure
} from '@chakra-ui/react';

import { useAuth, useRemoveQueryParams } from '../../hooks';
import LabelValue from './LabelValue';
import { useSubscription } from '../../queries';
import { SUBSCRIPTION_PLANS } from '../../constants';

const UpgradeSuccessModal = () => {
  const [searchParams] = useSearchParams();
  const removeQueryParams = useRemoveQueryParams();
  const isUpgradeSuccessful = searchParams.get('upgradeStatus') === 'success';
  const transactionId = searchParams.get('transactionId');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { user, token, userSubscriptionPlan } = useAuth();

  const { data: subscription, isLoading } = useSubscription({
    token,
    transactionId,
    isEnabled: isUpgradeSuccessful
  });

  React.useEffect(() => {
    if (
      isUpgradeSuccessful &&
      !!transactionId &&
      !isLoading &&
      userSubscriptionPlan !== SUBSCRIPTION_PLANS.PRO
    ) {
      setTimeout(() => {
        user.getIdToken(true);
      }, 3000);
    }
  }, [
    user,
    isUpgradeSuccessful,
    userSubscriptionPlan,
    subscription?.planType,
    transactionId,
    isLoading
  ]);

  React.useEffect(() => {
    if (isUpgradeSuccessful && !!transactionId) {
      onOpen();
    }
  }, [isUpgradeSuccessful, onOpen, transactionId]);

  if (!subscription && !isLoading) {
    return null;
  }

  const handleClose = () => {
    onClose();
    removeQueryParams(['upgradeStatus', 'transactionId']);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: '95%', md: '75%' }}
        maxW={{ base: '95%', md: '75%' }}
        minH={{ base: '90%', md: '75%' }}
        maxH={{ base: '90%', md: '75%' }}
        borderRadius="8px"
      >
        <ModalHeader
          bg="#5D38DB"
          color="white"
          borderTopLeftRadius="8px"
          borderTopRightRadius="8px"
        >
          <Box>
            <Image src="/assets/images/classwise-white-logo.svg" />
            <ModalCloseButton />
          </Box>
          <Center>
            <Text fontSize={{ base: '16px', md: '20px' }}>
              {isLoading
                ? 'Fetching your payment status'
                : `Thank you, ${
                    user?.displayName.split(' ')[0]
                  }. Your order was placed successfully`}
            </Text>
          </Center>
        </ModalHeader>
        <ModalBody
          p={{ base: '16px', md: '32px' }}
          bg="#E8EBF2"
          overflowY="scroll"
          borderRadius="8px"
        >
          {isLoading ? (
            <Center h="100%">
              <Spinner />
            </Center>
          ) : (
            <Grid templateColumns="repeat(12, 1fr)" gap={8}>
              <GridItem colSpan={{ base: 12, md: 7, lg: 7 }} w="100%">
                <Card>
                  <CardHeader>
                    <Heading size={{ base: 'sm', md: 'md' }} color="#686868">
                      Get Started with Classwise Pro
                    </Heading>
                  </CardHeader>
                  <CardBody fontSize={{ base: '14px', md: '15px' }}>
                    <Box>
                      Congratulations on successfully completing your payment for Classwise! 🎉
                    </Box>
                    <Box mt="2">
                      You&apos;re now equipped with a powerful tool that&apos;s set to transform
                      your learning experience.
                    </Box>
                    <Button
                      borderRadius="20px"
                      px="48px"
                      mt="16px"
                      bg="#5D38DB"
                      color="white"
                      onClick={handleClose}
                    >
                      Get Started
                    </Button>
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem colSpan={{ base: 12, md: 5, lg: 5 }} pt="4px">
                <Heading size="sm">Plan Details</Heading>
                <Flex mt="2" alignItems="center">
                  <Image src="/assets/images/classwise-logo-rectangle.png" />
                  <Text as="b" ml="2" fontSize="12">
                    Classwise Pro
                  </Text>
                </Flex>
                {!subscription ? (
                  <>
                    <Skeleton height="40px" mt={3} />
                    <Skeleton height="40px" mt={3} />
                    <Skeleton height="40px" mt={3} />
                    <Skeleton height="40px" mt={3} />
                  </>
                ) : (
                  <>
                    <LabelValue label="Confirmation Email Sent to:" value={user?.email} />
                    <LabelValue label="Order Number:" value={subscription?._id} />
                    <LabelValue
                      label="You are being charged:"
                      value={`${subscription?.currency.toUpperCase()} ${
                        subscription?.amount / 100
                      }/${subscription?.billingCycle?.interval}${
                        subscription?.taxMode === 'external' ? ' + taxes' : ''
                      }`}
                    />
                    <Box mt="8px">
                      <Text as="b" fontSize="12px">
                        Questions?
                      </Text>
                      <Box>
                        <Link href="https://getclasswise.com/contact-us" isExternal fontSize="12px">
                          Contact Us
                        </Link>
                      </Box>
                    </Box>
                  </>
                )}
              </GridItem>
            </Grid>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpgradeSuccessModal;
