import React, { useMemo } from 'react';
import {
  Flex,
  Box,
  Image,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Stack,
  Divider
} from '@chakra-ui/react';
import { useAuth } from '../../../hooks/useAuth';
import ProfilePhoto from './ProfilePhoto';
import { useNavigate } from 'react-router-dom';
import Upgrade from '../../upgrade/Upgrade';
import { Capacitor } from '@capacitor/core';
import UpgradeSuccessModal from '../../upgrade/UpgradeSuccessModal';

const AppHeader = (props) => {
  const {
    title,
    isIconNavigation = false,
    renderActions,
    time,
    totalQuestions,
    pageTitle = '',
    onBadgeOpen,
  } = props;
  const { user, signOut } = useAuth();
  const navigate = useNavigate();

  const isNativeApp = useMemo(() => Capacitor.isNativePlatform(), []);

  const onJoin = () => {
    navigate('/join');
  };

  return (
    <>
      <Flex
        justify="space-between"
        alignItems="center"
        borderBottom="0.5px"
        backgroundColor="#ffffff"
        borderColor="#979797"
        px={{ base: 2, md: 5 }}
        py={{ base: 2, md: 2 }}
        width="100%"
        shadow={'md'}
        // minHeight={{ base: '60px', md: '64px' }}
        zIndex={10}
        // Removing this due to issues on rendering assignment screens
        // position={'fixed'}
        // top={0}
      >
        <Stack
          direction="column"
          maxWidth={{ base: '170px', md: '500px' }}
          align="flex-start"
          justify="center"
        >
          {/* div cannot be descendent of p warning */}
          {typeof title === 'string' ? (
            <Box w={isIconNavigation ? '300px' : 'auto'}>
              <Text
                fontWeight="400"
                fontSize={{ base: 'sm', md: 'lg' }}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                ml={{ base: 2, md: 0 }}
                maxWidth={{ base: '150px', md: '250px' }}
              >
                {title}
              </Text>
            </Box>
          ) : (
            <Box
              w={isIconNavigation ? '300px' : 'auto'}
              fontWeight="400"
              fontSize={{ base: 'sm', md: 'lg' }}
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              ml={{ base: 2, md: 0 }}
              maxWidth={{ base: '150px', md: '250px' }}
            >
              {title}
            </Box>
          )}

          {totalQuestions && (
            <Text
              fontWeight="700"
              fontSize={{ base: 'sm', md: 'lg' }}
              borderColor="#5D38DB"
              borderWidth={1}
              p={1}
            >
              {'Total Questions: ' + totalQuestions}
            </Text>
          )}
        </Stack>
        {time && (
          <Flex
            dir="column"
            justify="center"
            align="center"
            alignSelf={'flex-start'}
            p={2}
            fontSize={{ base: '5sm', md: 'lg' }}
            fontWeight="600"
            fontStyle="italic"
            position={{ base: 'inline-flex', md: 'absolute' }}
            left={{ base: 'unset', md: '46%' }}
          >
            <Image
              src="/assets/images/stopwatch.jpg"
              alt="stopwatch"
              w={{ base: '24px', md: '36px' }}
            />
            <Text ml="2">{time?.minutes}</Text>
            <Text ml="2" color="#0000008A">
              :{time?.seconds}
            </Text>
          </Flex>
        )}

        <Flex justify="center" alignItems="center" p={2}>
          {!isNativeApp && <Upgrade />}
          {renderActions && renderActions()}
          <Box ml={3} mr={{ base: 2, md: 0 }} mt={{ base: 1, md: 0 }}>
            <Menu>
              <MenuButton as={ProfilePhoto} name={user?.displayName} />
              <MenuList>
                <MenuItem onClick={onJoin}>Join</MenuItem>
                <MenuItem onClick={onBadgeOpen}>Interests</MenuItem>
                <Divider />
                <MenuItem onClick={signOut}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
        {/*** Page subtitle */}

        {pageTitle && (
          <Flex
            justifyContent={'center'}
            width={300}
            position={'absolute'}
            margin={'0 auto'}
            left={0}
            right={0}
          >
            {/* div cannot be descendent of p warning */}
            {typeof pageTitle === 'string' ? (
              <Text fontWeight="700" textAlign={'center'} fontSize={{ base: 'md', md: 'lg' }} p={1}>
                {pageTitle}
              </Text>
            ) : (
              <Box fontWeight="700" fontSize={{ base: 'md', md: 'lg' }} p={1}>
                {pageTitle}
              </Box>
            )}
          </Flex>
        )}
        <UpgradeSuccessModal />
      </Flex>
    </>
  );
};

export default AppHeader;
