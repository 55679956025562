import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { getHomePageUrl } from '../utils';

const useRedirectToHome = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const redirectToHome = () => {
    toast({
      title: 'Invalid URL',
      description: 'Page not found!',
      status: 'error',
      duration: 2000,
      isClosable: true
    });
    navigate(getHomePageUrl());
  };
  return { redirectToHome };
};

export { useRedirectToHome };
