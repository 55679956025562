import { useCallback, useEffect, useRef, useState } from 'react';
import { useAuth } from '.';
import { appConfig } from '../constants';
import {
  CONNECTION_WAIT,
  MAX_CONNECTION_TRIES,
  SOCKET_STATUS
} from '../constants/socket-constants';

const useWebSocket = ({ onMessageRef, onReconnectionRef }) => {
  const { token } = useAuth();
  const wsRef = useRef();
  const readyStateWait = useRef(0);
  const [status, setStatus] = useState(SOCKET_STATUS.NOT_CONENCTED);
  const [isReconnecting, setIsReconnecting] = useState(false);
  const forceCloseState = useRef(false);
  const connectionTries = useRef(0);

  const reconnectionHandlerRef = useRef();

  useEffect(() => {
    // Connect the socket
    console.log('Connecting the socket!');
    connectSocket();

    // Cannot depend on anything else as this effect will run again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // Cleanup of socket if the hook is unmounted
  useEffect(() => {
    return () => {
      try {
        console.warn('Closing socket on cleanup');
        forceCloseState.current = true;
        wsRef.current?.close(1000, 'cleanup');
      } catch (e) {
        console.log('Try close for cleanup', e);
      }
    };
  }, []);

  const waitForSocketReady = useCallback(() => {
    readyStateWait.current = readyStateWait.current + 1;
    if (readyStateWait.current > 5) {
      throw new Error('Waited too much for socket to be ready');
    }

    setTimeout(() => {
      if (wsRef.current?.readyState === 1) {
        if (isReconnecting) {
          setIsReconnecting(false);
          onReconnectionRef.current();
        } else {
          console.log('Websocket is ready to send & receive messages');
          setStatus(SOCKET_STATUS.READY);
          readyStateWait.current = 0; // Reset ready state wait
          connectionTries.current = 0; // Reset the connection tries state
        }
      } else {
        waitForSocketReady();
      }
    }, 100);
  }, [isReconnecting, setIsReconnecting, onReconnectionRef]);

  const connectSocket = useCallback(() => {
    // If token not available or we already have a socket connection made, return
    console.log('Socket state was', wsRef.current?.readyState);
    if (!token || wsRef.current?.readyState === 0 || wsRef.current?.readyState === 1) {
      console.log(
        'Socket token unavailable or already connected, hence returning from connectSocket',
        {
          token: !!token,
          readyState: wsRef.current?.readyState
        }
      );
      return;
    }

    try {
      wsRef.current?.close(1000, 'reconnect');
    } catch (e) {
      console.log('Try close before connect', e);
    }

    if (connectionTries.current > MAX_CONNECTION_TRIES) {
      console.error('Exceeded retries on socket connect, please refresh the page.');
    }

    //Connect to websocket here
    connectionTries.current++;
    wsRef.current = new WebSocket(appConfig.wsURL, token);
    setStatus(SOCKET_STATUS.INITIATED);

    wsRef.current.addEventListener('open', () => {
      setStatus(SOCKET_STATUS.CONNECTED);
      waitForSocketReady();
    });

    wsRef.current.addEventListener('message', (event) => {
      const message = event.data;
      try {
        const parsedMessage = JSON.parse(message);
        onMessageRef.current(parsedMessage);
      } catch (e) {
        console.info('message not a valid JSON', e);
      }
    });

    // NOTE: Close will always fire when error is fired, so we need reconnection handler in the close event
    wsRef.current.addEventListener('close', () => {
      setStatus(SOCKET_STATUS.CLOSED);
      console.log('Websocket closed');

      // If we force closed the socket, return
      if (forceCloseState.current) {
        console.log('Force close is true, hence skipping reconnect');
        return;
      }

      // Try to reconnect to the closed socket
      reconnectionHandlerRef.current?.();
    });

    wsRef.current.addEventListener('error', () => {
      setStatus(SOCKET_STATUS.CLOSED);
      console.log('Websocket error');
    });
  }, [onMessageRef, token, waitForSocketReady]);

  const sendMessage = useCallback((message) => {
    if (wsRef.current?.readyState !== 1) {
      console.error('Socket not yet ready to send messages');
      return;
    }
    wsRef.current.send(JSON.stringify(message));
  }, []);

  // Used to force close the socket & not reconnect
  const forceCloseSocket = useCallback(() => {
    forceCloseState.current = true;
    // See https://www.rfc-editor.org/rfc/rfc6455.html#section-7.4
    wsRef.current?.close(1000, 'force-close');
  }, []);

  const reconnectionHandler = useCallback(() => {
    // Exponential backoff - waits for CONNECTION_WAIT seconds * number of retries
    setIsReconnecting(true);
    const waitTime = connectionTries.current * CONNECTION_WAIT;
    setTimeout(() => {
      connectSocket();
    }, waitTime);
  }, [connectSocket]);

  reconnectionHandlerRef.current = reconnectionHandler;

  return {
    sendMessage,
    status,
    forceCloseSocket
  };
};

export { useWebSocket };
