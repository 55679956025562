import { SUBSCRIPTION_PLANS } from '../constants';
import { get } from '../utils/APIClient';

export const getCurrentSubscription = async ({ token, transactionId }) => {
  const query = transactionId ? `?transactionId=${transactionId}` : '';
  return get({ url: `subscriptions${query}`, token });
};

export const getSubscriptionPrices = async ({ token }) => {
  const prices = await get({
    url: `subscriptions/prices?planType=${SUBSCRIPTION_PLANS.PRO}`,
    token
  });
  return prices.sort((a, b) => a.index - b.index);
};

export const getConsumptionDetails = async ({ token }) => {
  return get({ url: `subscriptions/consumption`, token });
};
